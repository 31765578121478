import { Contract } from "@ethersproject/contracts";
import { CONTRACT_NFT, CONTRACT_BID, CONTRACT_BID_VERSION } from "../constants";
import abiOpenMyBox from "../pages/abi/abiOpenMyBox.json";
import abiMyBox from "../pages/abi/abiMyBox.json";
import { useMemo } from "react";

import { getContract } from "../utils/index";

import { useActiveWeb3React } from "./index";
// returns null on errors
export function useContract(address, ABI, withSignerIfPossible = true) {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}