import web3 from "web3";
import BigNumber from "bignumber.js";
import { MaxUint256 } from '@ethersproject/constants'

const convertNumber = (value) => {
  return web3.utils.toHex(web3.utils.toWei(value, "ether"));
};
export const convertTokenToWei = (value, decimal) => {
    let amount = web3.utils.toWei(value.toString(), 'ether')
    if (decimal !== 18)
      amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
    return amount
  }
export const _approveBEP20 = async (contract, address, amount) => {
  const result = await contract.approve(
    address,
    "100000000000000000000000000000000"
  );
  return result;
};

export const _isClaimed = async (poolContract, account) => {
  return poolContract && poolContract.isClaimed(account);
};

export const _claimTokensNew = async (claimContract, reward , keyId , sign , tokenDecimals) => {
  const amount = convertTokenToWei(reward , tokenDecimals)
  let args = [amount, keyId, sign]
  const estimatedGas = await claimContract.estimateGas.claim(...args)
  return claimContract.claim(...args, {
    gasLimit: estimatedGas,
 })
}

export const _userClaim = async (claimContract, account, keyId) => {
  return claimContract.userClaim(...[account, keyId])
}

export const _totalClaimed = async (claimContract, account, tokenDecimals) => {
  const res = claimContract && (await claimContract.totalClaimed(account));
  return convertTokenToWei(res, tokenDecimals);
};

export const _approveToken = async (nftContract, address , tokenId) => {
  try {
    const result = await nftContract.approve(address , tokenId)
    return result
  } catch (error) {
    return false
  }
}

export const _refreshLiquidity = async (scStakeNftLpContract) => {
  const res = scStakeNftLpContract && await scStakeNftLpContract.updateLiquidity();
  return res;
};

export const _stake = async (scStakeContract, amount) => {
  try {
    const amountTemp = web3.utils.toWei(amount.toString(), 'ether')
    const args = [amountTemp / 1e18]
    console.log("args" , args)
    const estimatedGas = await scStakeContract.estimateGas.deposit(amount.toString())
    return scStakeContract.deposit(amount.toString(), {
      gasLimit: estimatedGas,
    })

  } catch (error) {
    return error
  }
}

export const _widthraw = async (scStakeContract , bool) => {
  try {
    const estimatedGas = await scStakeContract.estimateGas.withdraw( bool)
    return scStakeContract.withdraw( bool, {
      gasLimit: estimatedGas,
    })

  } catch (error) {
    return error
  }
}

export const _widthrawUnstake = async (scStakeContract , bool) => {
  try {
    const estimatedGas = await scStakeContract.estimateGas.withdraw( bool)
    return scStakeContract.withdraw( bool, {
      gasLimit: estimatedGas,
    })

  } catch (error) {
    return error
  }
}

export const _mintNFT = async (ScContract , to , amount , [_tokenId]) => {
  try {
    const amountTemp = amount * 1e18
    const estimatedGas = await ScContract.estimateGas.safeMintMany( to , amount , [_tokenId])
    return ScContract.safeMintMany( to , amount , [_tokenId] ,{
      gasLimit: estimatedGas,
    })
  } catch (error) {
    return error
  }
}