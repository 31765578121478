/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Progress, message, Spin } from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import switchNetwork from "../utils/wallet";
import { useContract } from "../hooks/useContract";
import { MAPPING_CHAINID } from "../constants";
import { postData } from "./Store"
import web3 from 'web3'
import AirDrop from "./abi/AirDrop.json"
import AbiContract from "./abi/AbiContract.json"
import { _claimTokensNew, _userClaim, _totalClaimed } from "./utils";
import { useActiveWeb3React } from '../hooks';
import { isMobile } from 'react-device-detect';
import ConnectWallet from "../component/connect-wallet/ConnectWallet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

declare const window: Window & typeof globalThis & { ethereum: any };
const HomePage = () => {
    const [dataMeme, setDataMeme] = useState<any>();
    const [dataMessage, setDataMessage] = useState<any>();
    const claimContract = useContract(dataMeme?.claimSC, AirDrop);
    const { account } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState<any>(0);
    let timeUpcoming = 1682521200000 - 7 * 60 * 60 * 1000
    let timeEnd = 1683363600000 - 7 * 60 * 60 * 1000

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }: any) => {
        // Render a countdown
        if (days > 1) {
            return (
                <div className="time">
                    <span> {days}</span> : <span>{hours} </span>: <span>{minutes}</span> : <span>{seconds}</span>
                </div>
            );
        } else if (days === 1) {
            return (
                <div className="time">
                    <span>{days}</span> : <span>{hours}</span> : <span>{minutes}</span> : <span>{seconds}</span>
                </div>
            );
        } else {
            return (
                <div className="time">
                    <span>{hours}</span> : <span>{minutes}</span> : <span>{seconds}</span>
                </div>
            );
        }
    };

    // useEffect(() => {
    //     if (account) {
    //         let data:any = {
    //             "ownerAddress": account
    //         }
    //         postData('https://api.hold.vip/api/meme/airdrop_whitelist',data).then((res)=>{
    //             setDataMeme(res.data)
    //             setDataMessage(res.message)
    //         })
    //     } else {
    //         let data:any = {
    //             "ownerAddress": "sss"
    //         }
    //         postData('https://api.hold.vip/api/meme/airdrop_whitelist',data).then((res)=>{
    //             setDataMeme(res.data)
    //             setDataMessage(res.message)
    //         })
    //     }
    // }, [account]);


    const handleClaim = async () => {
        setIsLoading(true);
        try {
            await _claimTokensNew(claimContract, dataMeme?.token, dataMeme?.keyId, dataMeme?.sign, 18).then(
                (res) => {
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setIsLoading(false);
                            setIsClaimed(true);
                        } else {
                            setIsLoading(false);
                            setIsClaimed(false)
                        }
                    });
                }
            )
        } catch (error: any) {
            setIsLoading(false);
            if (error?.error?.data) {
                message.error({
                    type: "error",
                    content: error?.error?.data?.message,
                    className: "custom-class",
                    duration: 2,
                });
            } else {
                if (error?.code === -32603) {
                    message.error("Cannot set properties of undefined")
                } else message.error({
                    type: "error",
                    content: error?.code,
                    className: "custom-class",
                    duration: 2,
                });
            }
        }
    };

    useEffect(() => {
        if (claimContract && dataMeme?.keyId > 0) {
            setIsLoadingClaim(true)
            _userClaim(claimContract, account, dataMeme?.keyId).then((res: any) => {
                setIsClaimed(res);
                setIsLoadingClaim(false)
            });
        }
    }, [account, claimContract, dataMeme?.keyId]);

    useEffect(() => {
        if (claimContract) {
            _totalClaimed(claimContract, account, 18).then((res: any) => {
                setTotalClaimed(res);
            });
        }
    }, [account, claimContract]);

    const _chainId = MAPPING_CHAINID["arb"];
    const [chain, setChain] = useState()

    useEffect(() => {
        window.ethereum?.request({ method: "eth_chainId" }).then((res: any) => {
            if (res !== _chainId) {
                switchNetwork(_chainId);
            }
            setChain(res)
        });
    }, [chain]);

    const [balanceOf, setBalance] = useState(0);
    const balanceContract: any = useContract("0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5", AbiContract);

    const getBlance = async () => {
        if (balanceContract) {
            const amount = await balanceContract.balanceOf("0x57Ba5B7898BE1fc3552aE3Da036ed920157feCEF");
            setBalance(amount.toString() / 1e18);
        }
    };

    useEffect(() => {
        getBlance()
    }, [balanceContract]);

    const totalPoolClaim = 79990000000000 - balanceOf
    const percentProcess = totalPoolClaim / 79990000000000 * 100
    let percentClaimed = percentProcess.toFixed(2)

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <>
                <div className="next" onClick={onClick}>
                    <img src="./images/next.svg" alt="" />
                </div>
            </>
        );
    }

    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <>
                <div className="prev" onClick={onClick}>
                    <img src="./images/prev.svg" alt="" />
                </div>
            </>
        );
    }

    const settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const gotoGame = () => {
        window.open('https://chromewebstore.google.com/detail/xhold/iljpoacgkanekbhiljbgfjmnmffbnmnn')
    }

    return (
        <>
            <div className="main-wrap-section">
                <div className="main-banner">
                    <div className="container">
                        <div className="columns">
                            <div className="colum w-7">
                                {isMobile ?
                                    <>
                                        <div className="address-wallet-banner">
                                            <span className="main-color font-16">Contract</span>:
                                            0xb4...48a5
                                            <CopyToClipboard
                                                text={`0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5`}
                                                onCopy={() => {
                                                    message.success({
                                                        type: "error",
                                                        content: "Copied",
                                                        className: "custom-class",
                                                        duration: 2,
                                                    });
                                                }}
                                            >
                                                <span><img src="./images/copied.png" alt="" /></span>
                                            </CopyToClipboard>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="address-wallet-banner">
                                            <span className="main-color font-16">Contract</span>:
                                            0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5
                                            <CopyToClipboard
                                                text={`0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5`}
                                                onCopy={() => {
                                                    message.success({
                                                        type: "error",
                                                        content: "Copied",
                                                        className: "custom-class",
                                                        duration: 2,
                                                    });
                                                }}
                                            >
                                                <span><img src="./images/copied.png" alt="" /></span>
                                            </CopyToClipboard>
                                        </div>
                                    </>
                                }
                                <div className="title-banner">
                                    If you can't <span className="main-color">HOLD</span>, you won't be rich.
                                </div>
                                <div className="desc-banner">
                                    Hey there, peeps! Are you ready to get RICH? Look no <br /> further than $HOLD – the meme coin project that'll make <br /> your portfolio POP!
                                </div>
                                {isMobile ?
                                    <>
                                        <div className="gr-social">
                                            <button type="button" className="sc-network" onClick={() => window.open("https://arbiscan.io/token/0xb4bbfe92702730ef7f1d28e4b9e42381182f48a5")}>
                                                <img src="./images/arb.svg" alt="" />
                                            </button>
                                            <button type="button" className="sc-network" onClick={() => window.open("https://twitter.com/holdvip")}>
                                                <img src="./images/twi.svg" alt="" />
                                            </button>
                                            <button type="button" className="sc-network" onClick={() => window.open("https://t.me/holdvip")}>
                                                <img src="./images/tele.svg" alt="" />
                                            </button>
                                        </div>
                                        <div className="button-mobile">
                                            <div className="button-gr">
                                                <button type="button" className="btn-buynow" onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5")}>
                                                    Buy Now
                                                </button>
                                            </div>
                                            <div className="button-gr">
                                                <ConnectWallet />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="gr-social">
                                            <button type="button" className="sc-network" onClick={() => window.open("https://arbiscan.io/token/0xb4bbfe92702730ef7f1d28e4b9e42381182f48a5")}>
                                                <img src="./images/arb.svg" alt="" /> <span>Arbitrum</span>
                                            </button>
                                            <button type="button" className="sc-network" onClick={() => window.open("https://twitter.com/holdvip")}>
                                                <img src="./images/twi.svg" alt="" /> <span>Twitter</span>
                                            </button>
                                            <button type="button" className="sc-network" onClick={() => window.open("https://t.me/holdvip")}>
                                                <img src="./images/tele.svg" alt="" /> <span>TeleGram</span>
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="colum w-5">

                                <video className="video-game" width={'100%'} height={"100%"} muted autoPlay onClick={gotoGame}>
                                    <source src="./images/xhold.mp4" type="video/mp4" />

                                </video>
                                {/* <iframe width="400"
                                    height="620"
                                    frameBorder="0"
                                    allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
                                    src="https://app.uniswap.org/#/swap?outputCurrency=0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5
                                " >
                                </iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-own-nft">
                    <div className="container">
                        <div className="content-onw-nft">
                            <div className="title">
                                <div className="img-nft-1">
                                    <img src="./images/ab-1.png" alt="" />
                                </div>
                                DISCOVER OUR NFT COLLECTION
                                <div className="img-nft-2">
                                    <img src="./images/ab-2.png" alt="" />
                                </div>
                            </div>
                            <div className="desc">
                                Introducing HOLD VIP, where we've infused the iconic $HOLD with hilarious vibes that'll have you ROFLing.HOLD VIP NFTs are MADE WITH PULSE, giving them a unique energy and making them stand out.
                            </div>
                            <div className="gr-button">
                                <Link to="/own">
                                    <button type="button" className="btn-open">
                                        Own NFT
                                    </button>
                                </Link>
                                <button type="button" className="btn-open-sea" onClick={() => window.open('https://opensea.io/collection/hold-vip-collection')}>
                                    Trade on OpenSea
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-img-nft">
                        <div className="item">
                            <img src="./images/s-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-2.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-3.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-4.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-5.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-7-7.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-7.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-8.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-9.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-10.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-11.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-12.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="./images/s-13.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="main-about">
                    <div className="img-ab-l">
                        <img src="./images/ab-r.png" alt="" />
                    </div>
                    <div className="img-ab-r">
                        <img src="./images/ab-l.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="content-about">
                            <div className="list-chain-about">
                                <li>
                                    <div className="content-chain" onClick={() => window.open("https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5")}>
                                        <img src="./images/chain-uni.svg" alt="" /> <span className="name-chain">UniSwap</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain" onClick={() => window.open("https://www.dextools.io/app/en/arbitrum/pair-explorer/0x299b378d1feeac8fec756f041ab1117f7d6c8df5")}>
                                        <img src="./images/chain-dex.png" alt="" /> <span className="name-chain">Dextools</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain" onClick={() => window.open("https://dexscreener.com/arbitrum/0x299b378d1feeac8fec756f041ab1117f7d6c8df5")}>
                                        <img src="./images/dexss.png" alt="" /> <span className="name-chain">Dex Screener</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain" onClick={() => window.open("https://www.coingecko.com/en/coins/hold-vip")}>
                                        <img src="./images/chain-gecko.png" alt="" /> <span className="name-chain">Coingecko</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain" onClick={() => window.open("https://coinmarketcap.com/currencies/hold-vip/")}>
                                        <img src="./images/chain-marketcap.png" alt="" /> <span className="name-chain">Coinmarketcap</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain" onClick={() => window.open("https://www.mexc.com/exchange/HOLD_USDT")}>
                                        <img src="./images/chain-mex.png" alt="" /> <span className="name-chain">Mexc</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-houbi.png" alt="" /> <span className="name-chain">Huobi (Coming soon)</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-gate.png" alt="" /> <span className="name-chain">Gate.io (Coming soon)</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-bybit.png" alt="" /> <span className="name-chain">Bybit (Coming soon)</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-bingx.png" alt="" /> <span className="name-chain">BingX (Coming soon)</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-poloinex.png" alt="" /> <span className="name-chain">Poloniex (Coming soon)</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-okx.png" alt="" /> <span className="name-chain">OKX (Coming soon)</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="content-chain opacity">
                                        <img src="./images/chain-bitget.png" alt="" /> <span className="name-chain">Bitget (Coming soon)</span>
                                    </div>
                                </li>
                            </div>
                            <div className="title-about">
                                About
                            </div>
                            <div className="desc-about">
                                If you can't <span className="main-color">hold</span>, you won't be rich.
                            </div>
                            <div className="table-about">
                                <div className="columns">
                                    <div className="colum w-4">
                                        <div className="guide-swap">
                                            <div className="content">
                                                We're all about creating a decentralized community of like-minded folks who wanna build wealth and earn passive income. And we're doing it on the Arbitrum blockchain – which means fast and secure transactions, plus low fees!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-4">
                                        <div className="guide-swap">
                                            <div className="content">
                                                But wait, there's more! We know investing in crypto can be confusing AF, so we've made it easy-peasy-lemon-squeezy to get involved with our community-driven project. We're all about holding onto our investments for the long term, baby – none of that quick profit nonsense here!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-4">
                                        <div className="guide-swap">
                                            <div className="content">
                                                So what are you waiting for? Join the $HOLD crew and let's get RICH together! Invest now and let's build some serious wealth.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how-to-buy">
                    <div className="container">
                        <div className="swrap-how-to-buy">
                            <div className="title-buy">
                                How to buy
                            </div>
                            <div className="list-buy">
                                <div className="item-buy">
                                    <div className="left">
                                        <div className="box-icon">
                                            <img src="./images/wallet-add.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="txt">
                                            Create a wallet
                                        </div>
                                        <div className="desc">
                                            Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io
                                        </div>
                                    </div>
                                </div>
                                <div className="item-buy">
                                    <div className="left">
                                        <div className="box-icon">
                                            <img src="./images/ETH.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="txt">
                                            Get some ETH
                                        </div>
                                        <div className="desc">
                                            have ETH in your wallet to switch to HOLD. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.
                                        </div>
                                    </div>
                                </div>
                                <div className="item-buy">
                                    <div className="left">
                                        <div className="box-icon">
                                            <img src="./images/unii.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="txt">
                                            Go to UniSwap
                                        </div>
                                        <div className="desc">
                                            go to https://app.uniswap.org/ in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the HOLD token address into the Uniswap, select HÔLD, and confirm. When Metamask prompts you for a wallet signature, sign.
                                        </div>
                                    </div>
                                </div>
                                <div className="item-buy">
                                    <div className="left">
                                        <div className="box-icon">
                                            <img src="./images/swap.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="txt">
                                            Switch ETH for HOLD
                                        </div>
                                        <div className="desc">
                                            Switch ETH for HOLD. So set Slippage to 10% and then click buy.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-holdonomic">
                    <div className="img-tax">
                        <img src="./images/img-tax.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="content-holdonomic">
                            <div className="title-holdonomic">
                                Holdonomic
                            </div>
                            <div className="desc-holdonomic">
                                TOKEN METRICS HOLD TOKEN <span className="tax-fee-box">No tax, Contract owner renounced, UniSwap position NFT burnt</span>
                            </div>
                            <ul className="list-holdonomic">
                                {isMobile ?
                                    <>
                                        <li>
                                            <div className="txt-l">
                                                Contract:
                                            </div>
                                            <div className="txt-r">
                                                0xb4...48a5
                                                <CopyToClipboard
                                                    text={`0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5`}
                                                    onCopy={() => {
                                                        message.success({
                                                            type: "error",
                                                            content: "Copied",
                                                            className: "custom-class",
                                                            duration: 2,
                                                        });
                                                    }}
                                                >
                                                    <span><img src="./images/copied.png" alt="" /></span>
                                                </CopyToClipboard>
                                            </div>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li>
                                            <div className="txt-l">
                                                Contract:
                                            </div>
                                            <div className="txt-r">
                                                0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5
                                                <CopyToClipboard
                                                    text={`0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5`}
                                                    onCopy={() => {
                                                        message.success({
                                                            type: "error",
                                                            content: "Copied",
                                                            className: "custom-class",
                                                            duration: 2,
                                                        });
                                                    }}
                                                >
                                                    <span><img src="./images/copied.png" alt="" /></span>
                                                </CopyToClipboard>
                                            </div>
                                        </li>
                                    </>
                                }
                                <li>
                                    <div className="txt-l">
                                        Symbol
                                    </div>
                                    <div className="txt-r">
                                        HOLD <img src="./images/logo-token.png" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="txt-l">
                                        Total Suplpy:
                                    </div>
                                    <div className="txt-r">
                                        421.000.000.000.000
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="table-holdonomic">
                            <div className="columns align-center">
                                <div className="colum w-6">
                                    <div className="content-tokenomic">
                                        <div className="box-img">
                                            <img src="./images/mic-new.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="content-tokenomic">
                                        <div className="list-tokenomic">
                                            <li>
                                                <div className="text-l">
                                                    <span className="number">
                                                        51%
                                                    </span>
                                                    <span className="txt">
                                                        Liquidity
                                                    </span>
                                                </div>
                                                <div className="text-right">
                                                    214.710.000.000.000
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-l">
                                                    <span className="number">
                                                        19%
                                                    </span>
                                                    <span className="txt">
                                                        Airdrop
                                                    </span>
                                                </div>
                                                <div className="text-right">
                                                    79.990.000.000.000
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-l">
                                                    <span className="number">
                                                        20%
                                                    </span>
                                                    <span className="txt">
                                                        Growth up incentives
                                                    </span>
                                                </div>
                                                <div className="text-right">
                                                    84.200.000.000.000
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-l">
                                                    <span className="number">
                                                        10%
                                                    </span>
                                                    <span className="txt">
                                                        Listing & marketing
                                                    </span>
                                                </div>
                                                <div className="text-right">
                                                    42.100.000.000.000
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="swap-tax-fee">
                            <div className="tax-left">
                                Tax buy sell: <span className="main-color">5%</span>
                            </div>
                            <div className="tax-center">
                                <img src="./images/arrow.png" alt="" />
                            </div>
                            <div className="tax-right">
                                <div className="item">
                                    Team: <span className="main-color">2.5%</span>
                                </div>
                                <div className="item">
                                    Burn: <span className="main-color">1.5%</span>
                                </div>
                                <div className="item">
                                    Liquidity:: <span className="main-color">1%</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="wrap-statics">
                    <div className="container">
                        <div className="content-statics">
                            <div className="title-statics">
                                Burn Statistics
                            </div>
                            <div className="guide-static">
                                <div className="left">
                                    <div className="txt-top">
                                        22,757.3T
                                    </div>
                                    <div className="txt-bottom">
                                        Total $HOLD Destroyed
                                    </div>
                                </div>
                                <div className="center">
                                    <img src="./images/static.svg" alt="" />
                                </div>
                                <div className="right">
                                    <div className="txt-top">
                                        5.4055%
                                    </div>
                                    <div className="txt-bottom">
                                        Amount of Burned HOLD (%)
                                    </div>
                                </div>
                            </div>
                            {isMobile ?
                                <>
                                    <div className="destroy-adress">
                                        <div className="address-ds">
                                            Destroyed Address:
                                        </div>
                                        <div className="dead" onClick={() => window.open("https://arbiscan.io/address/0x000000000000000000000000000000000000dead")}>
                                            0x000...0dead <img src="./images/link.svg" alt="" />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="destroy-adress">
                                        <div className="address-ds">
                                            Destroyed Address:
                                        </div>
                                        <div className="dead" onClick={() => window.open("https://arbiscan.io/address/0x000000000000000000000000000000000000dead")}>
                                            0x000000000000000000000000000000000000dead <img src="./images/link.svg" alt="" />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="wrap-five-item">
                    <div className="container">
                        {isMobile ?
                            <>
                                <Slider {...settings}>
                                    <div className="item">
                                        <div className="content-item">
                                            <div className="box-img">
                                                <img src="./images/img-1.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                Hold
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <Link to="/earn">
                                            <div className="content-item">
                                                <div className="box-img">
                                                    <img src="./images/img-2.svg" alt="" />
                                                </div>
                                                <div className="txt">
                                                    Earn
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    {/* <div className="item">
                                        <div className="content-item">
                                            <div className="coming-soon">
                                                <img src="./images/coming.svg" alt="" />
                                            </div>
                                            <div className="box-img">
                                                <img src="./images/img-3.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                Rich
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="item">
                                        <Link to="/own">
                                            <div className="content-item">
                                                <div className="box-img">
                                                    <img src="./images/img-4.svg" alt="" />
                                                </div>
                                                <div className="txt">
                                                    Own NFT
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <div className="content-item">
                                            <div className="coming-soon">
                                                <img src="./images/coming.svg" alt="" />
                                            </div>
                                            <div className="box-img">
                                                <img src="./images/img-7.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                Freedom
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </>
                            :
                            <>
                                <div className="columns">
                                    <div className="colum w-2">
                                        <div className="content-item">
                                            <div className="box-img">
                                                <img src="./images/img-1.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                Hold
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-2">
                                        <div className="content-item">
                                            <div className="coming-soon">
                                                <img src="./images/coming.svg" alt="" />
                                            </div>
                                            <div className="box-img">
                                                <img src="./images/img-2.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                Earn
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-2">
                                        {/* <Link to="/rich"> */}
                                        <a href="https://x.hold.vip/" target="_blank">
                                            <div className="content-item">
                                                <div className="box-img">
                                                    <img src="./images/img-3.svg" alt="" />
                                                </div>
                                                <div className="txt">
                                                    Rich
                                                </div>
                                            </div>
                                        </a>

                                        {/* </Link> */}
                                    </div>
                                    <div className="colum w-2">
                                        <Link to="/own">
                                            <div className="content-item">
                                                <div className="box-img">
                                                    <img src="./images/img-4.svg" alt="" />
                                                </div>
                                                <div className="txt">
                                                    Own NFT
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="colum w-2" >
                                        <div className="content-item">
                                            <div className="coming-soon">
                                                <img src="./images/coming.svg" alt="" />
                                            </div>
                                            <div className="box-img">
                                                <img src="./images/img-7.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                Freedom
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomePage