/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";
import { Progress , message , Spin} from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { MAPPING_CHAINID } from "../../constants";
import switchNetwork from "../../utils/wallet";
import { useNavigate } from "react-router-dom";
import { useContract } from "../../hooks/useContract";
import HoldNftABI from "../abi/HoldNftABI.json"
import AbiContract from "../abi/AbiContract.json"
import NumericInput from "react-numeric-input";
import { _mintNFT , _approveToken} from "../utils";
import {isMobile} from 'react-device-detect';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {postData} from "../Store"

declare const window: Window & typeof globalThis & { ethereum: any };
const Own = () => {
    const { account } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);
    const [dataMint , setDataMint] = useState<any>();
    const [dataUpdate , setDataUpdate] = useState<any>();
    const [dataItem , setDataItem] = useState<any>();
    const [quantity, setQuantity]: any = useState(1);
    const [dataItemArray , setDataItemArray] = useState([]);
    const [dataItemAfter , setDataItemAfter] = useState([]);
    const [isApprove, setApprove] = useState(false);
    const contractMint = "0x3914187eD4cA9B367435239f374B8DF5332dbF03"
    const ScContractMint = useContract(contractMint, HoldNftABI);
    const contractToken = "0xb4bbfE92702730ef7F1d28e4b9E42381182F48a5"
    const ScContractToken = useContract(contractToken, AbiContract);

    const _chainId = MAPPING_CHAINID["arb"];
    const [chain , setChain] = useState()

    useEffect(() => {
        window.ethereum?.request({ method: "eth_chainId" }).then((res :any) => {           
            if (res !== _chainId) {
                switchNetwork(_chainId);
            }
            setChain(res)
        });
    }, [chain]);

    useEffect(() => {
        if (account) {
            let data:any = {
                "ownerAddress": account
            }
            postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                setDataMint(res.data)
                setDataItemAfter(res.data.nftItems)
            })
        } else {
            let data:any = {
                "ownerAddress": "sss"
            }
            postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                setDataMint(res.data)
            })
        }
	}, [account]);

    const [balanceOf, setBalance] = useState(0);
    console.log("balanceOf" , balanceOf)
    const getBlance = async () => {
        if (ScContractToken) {
          const amount = await ScContractToken.balanceOf("0x3914187eD4cA9B367435239f374B8DF5332dbF03");
          setBalance(amount.toString() / 1e18);
        }
    };

    useEffect(() => {
        getBlance()
    }, [ScContractToken]);

    const totalSp = balanceOf / 10000000000

    const handleApprove = async () => {
        setIsLoading(true);
        await _approveToken(ScContractToken , contractMint , 1000)
            .then((res:any) => {
                res.wait().then((res1: any) => {
                if (res1 !== null) {
                    message.success("Approved successfully!!! ");
                    setIsLoading(false);
                    setApprove(true)
                } else {
                    setIsLoading(false);
                }
                });
            })
                .catch((error:any) => {
                setIsLoading(false);
        });
    };

    useEffect(() => {
        if (ScContractToken && account !== undefined) {
            ScContractToken.allowance(account, contractMint).then((res: any) => {
            if (res.toString() / 1e18 > 0) {
                setApprove(true);
            } else {
                setApprove(false);
            }
          });
        }
    }, [account]);
    

    const handleMint = async () => {
        setIsLoading(true)
        try {
            let data:any = {
                "ownerAddress": account
            }
            postData('https://api.hold.vip/api/nft/get_items',data).then( async (res)=>{
                setDataItem(res.data)
                setDataItemArray(res.data.attrs)
                await _mintNFT(ScContractMint, account , 1 , [res.data.tokenId] ).then( async (resMint: any) => {
                    resMint.wait().then((resWaitSC: any) => {
                        if (resWaitSC.status === 1) {
                            let data:any = {
                                "ownerAddress": account,
                                "tokenId": res.data.tokenId,
                                "txHash": resMint.hash
                            }
                            postData('https://api.hold.vip/api/nft/nft_update',data).then((resAfter)=>{
                                setDataUpdate(resAfter.data)
                                message.success({
                                    type: "success",
                                    content: "Mint Success !",
                                    className: "custom-class",
                                    duration: 2,
                                });
                                let data:any = {
                                    "ownerAddress": account
                                }
                                postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                                    setDataMint(res.data)
                                    setDataItemAfter(res.data.nftItems)
                                })
                            })
                            setIsLoading(false)
                        } else {
                            message.error(res?.error?.message);
                            setIsLoading(false)
                        }
                    })
                })
            })
        } catch (error:any) {
            if (error) {
                message.error(error?.error.message);
                console.log("error",error)
                setIsLoading(false)
            }      
        }
    }

    function SampleNextArrow(props:any) {
        const { onClick } = props;
        return (
            <>
                <div className="next" onClick={onClick}>
                    <img src="./images/next.svg" alt="" />
                </div>
            </>
        );
      }
      
      function SamplePrevArrow(props:any) {
        const { onClick } = props;
        return (
            <>
                <div className="prev" onClick={onClick}>
                    <img src="./images/prev.svg" alt="" />
                </div>
            </>
        );
    }

    const settings = {
        arrows:false,
        infinite: true,
        dots:true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    const settingsRow = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: true,
        dots:false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const ItemNft = ({item}:any) => {
        return (
            <>
                <div className="row-nft">
                    <img style={{background: item?.person?.backgroundColor}} src={item?.imageUrl} alt="" />
                    {item.attrs && item.attrs.map((data:any , index:any) => (
                        <div className="img-details-after">
                            <img src={data.imageUrl} alt="" />
                        </div>
                    ))}
                </div>
            </>
        )
    }

    const navigate = useNavigate();
    
    const handlePage = () => {
        navigate("/ownDetails");
    }

    return (
        <>
             <div className="main-wrap-section res cus">
                <div className="container">
                    <div className="content-own-section">
                        {!isMobile ? 
                            <>
                                <div className="round-img-nft">
                                    <div className="title-img-nft">
                                        5ive Different Bases - 88 Attributes
                                    </div>
                                    <div className="columns">
                                        <div className="colum w-2">
                                            <div className="box-img">
                                                <img src="./images/n-1.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="colum w-2">
                                            <div className="box-img">
                                                <img src="./images/n-2.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="colum w-2">
                                            <div className="box-img">
                                                <img src="./images/n-3.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="colum w-2">
                                            <div className="box-img">
                                                <img src="./images/n-4.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="colum w-2">
                                            <div className="box-img">
                                                <img src="./images/n-5.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                        <div className="round-mint-nft">
                            <div className="title">
                                HOLDers collection
                            </div>
                            <div className="desc">
                                HOLDers by HOLD. 10,000 NFT HOLDers MADE WITH PULSE.
                            </div>
                            <div className="box-mint-nft">
                                <div className="box-img">
                                    <img src="./images/default.png" alt="" />
                                    <div className="open-nft" onClick={() => window.open('https://opensea.io/collection/hold-vip-collection')}>Trade on OpenSea</div>
                                </div>
                               
                                <div className="title-mint">
                                    Own your own
                                </div>
                                <div className="process-claim">
                                    <div className="title-process">
                                        {Number(totalSp.toFixed(0)) / 10000 *100}%  minted
                                        <div className="percent-claimed">
                                            {Number(totalSp.toFixed(0))} / 10,000
                                        </div>
                                    </div>
                                    <Progress percent={Number(totalSp.toFixed(0)) / 10000 *100} status="active" strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
                                </div>
                                <div className="box-public-stage">
                                    <div className="columns">
                                        <div className="colum w-8">
                                            <div className="content-public-stage">
                                                <div className="title-stage">
                                                    Open Minting
                                                </div>
                                                <div className="number-balance">
                                                    {dataMint?.publicStage} HOLD
                                                </div>
                                                <div className="limit-number">
                                                    Limit {dataMint?.totalMint}/{dataMint?.limitMint} per wallet
                                                </div>
                                                <div className="numberic-plus">
                                                    {/* <NumericInput
                                                        min={0}
                                                        max={dataMint?.limitMint}
                                                        strict
                                                        value={1}
                                                        // onChange={(e: any) => {
                                                        //     setQuantity(e);
                                                        // }}
                                                        mobile
                                                        className="form-control"
                                                    /> */}
                                                    <div className="button-round">
                                                        {!isApprove ? 
                                                            <>
                                                                {isLoading ? 
                                                                    <>
                                                                        <button className="btn-mint dis" type="button">
                                                                            Approve <Spin />
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button className="btn-mint" type="button" onClick={handleApprove}>
                                                                            Approve
                                                                        </button>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {isLoading ?
                                                                    <>
                                                                        <button className="btn-mint dis" type="button">
                                                                            Mint <Spin />
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button className="btn-mint" type="button" onClick={handleMint}>
                                                                            Mint
                                                                        </button>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-4">
                                            <div className="box-img-default">
                                                {dataItemAfter.length === 0 ? 
                                                    <>
                                                        <img src="./images/img-df.png" alt="" />
                                                    </>
                                                    :
                                                    <>
                                                        <Slider {...settingsRow}>
                                                            {dataItemAfter && dataItemAfter.map((item:any , index:any) => (
                                                                <ItemNft item={item} />
                                                            ))}
                                                        </Slider>
                                                        <div className="btn-view-all">
                                                            <button className="btn-view" type="button" onClick={handlePage}>
                                                                View all
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-your-nft">
                                <div className="guide-nft">
                                    <Slider {...settings}>
                                        <div className="item">
                                            <img src="./images/r-2.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-4.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-3.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-1.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-5.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-6.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-7.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-8.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-9.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/r-10.png" alt="" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}
export default Own