/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";

const Staking = () => {

    const StakingHold = () => {
        return (
            <>
                <div className="box-staking-row">
                    <div className="top-row">
                        <div className="title-row">
                            HOLD Staking Statistics
                        </div>
                        <div className="columns">
                            <div className="colum w-6">
                                <div className="guide-earn">
                                    <div className="txt-top">
                                        Current Staking
                                    </div>
                                    <div className="txt-bottom">
                                        $48,135,000,000,000
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="guide-earn">
                                    <div className="txt-top">
                                        APY
                                    </div>
                                    <div className="txt-bottom">
                                        12,023.78 %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-row">
                        <div className="title-row">
                            Stake HOLD earn RICH
                        </div>
                        <div className="columns">
                            <div className="colum w-6">
                                <div className="content-staking">
                                    <div className="guide-row">
                                        <div className="title">
                                            My Staking
                                        </div>
                                        <div className="box-img">
                                            <img src="./images/hold-earn.svg" alt="" />
                                        </div>
                                        <div className="reward-row">
                                            N/A
                                        </div>
                                        <div className="button-row">
                                            <button className="btn-claim-rw dis" type="button">
                                                Start Staking
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="content-staking">
                                    <div className="guide-row">
                                        <div className="title">
                                            My Reward
                                        </div>
                                        <div className="box-img">
                                            <img src="./images/rich.png" alt="" />
                                        </div>
                                        <div className="reward-row">
                                            N/A
                                        </div>
                                        <div className="button-row">
                                            <button className="btn-claim-rw dis" type="button">
                                                Claim
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const StakingArb = () => {
        return (
            <>
                <div className="box-staking-row">
                    <div className="top-row">
                        <div className="title-row">
                            HOLD Staking Statistics
                        </div>
                        <div className="columns">
                            <div className="colum w-6">
                                <div className="guide-earn">
                                    <div className="txt-top">
                                        Current Staking
                                    </div>
                                    <div className="txt-bottom">
                                        $48,135,000,000,000
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="guide-earn">
                                    <div className="txt-top">
                                        APY
                                    </div>
                                    <div className="txt-bottom">
                                        12,023.78 %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-row">
                        <div className="title-row">
                            Stake HOLD earn ARB
                        </div>
                        <div className="columns">
                            <div className="colum w-6">
                                <div className="content-staking">
                                    <div className="guide-row">
                                        <div className="title">
                                            My Staking
                                        </div>
                                        <div className="box-img">
                                            <img src="./images/hold-earn.svg" alt="" />
                                        </div>
                                        <div className="reward-row">
                                            N/A
                                        </div>
                                        <div className="button-row">
                                            <button className="btn-claim-rw dis" type="button">
                                                Start Staking
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="content-staking">
                                    <div className="guide-row">
                                        <div className="title">
                                            My Reward
                                        </div>
                                        <div className="box-img">
                                            <img src="./images/arb-1.png" alt="" />
                                        </div>
                                        <div className="reward-row">
                                            N/A
                                        </div>
                                        <div className="button-row">
                                            <button className="btn-claim-rw dis" type="button">
                                                Claim
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="main-wrap-section res cus">
                <div className="img-banner-l res">
                    <img src="./images/ban-earn-1.png" alt="" />
                </div>
                <div className="img-banner-r res">
                    <img src="./images/ban-earn-2.png" alt="" />
                </div>
                <div className="container">
                    <div className="content-earn">
                        <div className="title-earn">
                            <div className="img-round-1">
                                <img src="./images/img-round-1.png" alt="" />
                            </div>
                                Staking to <span className="main-color">RICH</span>
                            <div className="img-round-2">
                                <img src="./images/img-round-2.png" alt="" />
                            </div>
                        </div>
                        <StakingHold />
                        <StakingArb />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Staking