import React, { useEffect, useState } from 'react';
import { Button, Drawer, Modal , message } from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useConnectWallet, useActiveWeb3React } from '../../hooks';
import { NEED_A_PLACEHOLDER, WALLET_LIST } from '../../constants/index'; 
import WalletItem from './WalletItem';
import {  useWeb3React } from '@web3-react/core';
import web3 from "web3";
import "antd/dist/antd.min.css";
import "./style.css"

declare const window: Window & typeof globalThis & { ethereum: any };
const ConnectWallet = (props:any) => {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const { walletLogin, walletLogout } = useConnectWallet();
	const walletItemClass = 'w-1/3 flex-auto mb-0.5 p-0.5 last:bg-transparent';
	const chainId:any = 56;
	const { account } = useActiveWeb3React();
	const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";

	let w3: any = '';
	if (typeof window !== "undefined") {
		w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider)
  	}
	const [bnbBalance, setBnbBalance] = useState(0);

	useEffect(() => {
		if (account) {
			w3.eth.getBalance(account, (err:any, balance:any) => {
				if (balance) {
				let _balance: any = web3.utils.fromWei(
					web3.utils.toBN(balance),
					"ether"
				);
				let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
					setBnbBalance(bnbBl);
				}
			});
		}
	}, [account]);

	const context = useWeb3React();
	const { active } = context;

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

  const [isModalVisibleAcc, setIsModalVisibleAcc] = useState<boolean>(false);

  const showModalAccount = () => {
		setIsModalVisibleAcc(true);
	};

	const handleOkAccount = () => {
		setIsModalVisibleAcc(false);
	};

	const handleCancelAccount = () => {
		setIsModalVisibleAcc(false);
	};

	const [open, setOpen] = useState(false);
  const [openAcc, setOpenAcc] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const accountEllipsis = active ? `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}` : 'Connect Wallet';
	const accountEllipsisWl = active ? `${account?.substring(0, 5)}...${account?.substring(account.length - 5)}` : 'Connect Wallet';

	const handleDisconnect = () => {
		walletLogout();
		setIsModalVisibleAcc(false);
	};

	const handleConnect = async (connectorId: string) => {
		try {
			const rs = await walletLogin(connectorId);
			setIsModalVisible(false);
		} catch (e) {
			console.error('Login failed');
		}
	};

	const changeNetwork = async (chainId:any) => {
		try {
			await window.ethereum.enable();
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: chainId }]
			});
		} catch (error) {
			//@ts-ignore
			if (error.code === 4902) {
				try {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [{ chainId: chainId, rpcUrl: process.env.REACT_APP_BSC_NETWORK_URL ?? '' /* ... */ }]
					});
				} catch (addError) {
					// handle "add" error
				}
			}
			console.error(error);
		}
	};


	const titleWallet = (
		<>
			<div className="logo-wallet">
				<div className="box-logo">
					<img src="./images/logo.svg" alt="" />
				</div>
			</div>
		</>
	)

	return (
		<>
			{active ? 
				<>
					<button className="btn-disconnect" onClick={showModalAccount}>
						<div className="txt-l">
							<div className="box-img">
								<img src="./images/arb-cnt.svg" alt="" />
							</div>
							<div className="balance-arb">
								<span className="number">{bnbBalance > 0 ? bnbBalance : "0.00"}</span> <span className="name">ETH</span>
							</div>
						</div>
						<div className="txt-r">
							<div className="box-img">
								<img src="./images/meta.png" alt="" />
							</div>
							<span className="color-account">{accountEllipsis}</span>
							<CopyToClipboard
								text={`${account}`}
								onCopy={() => {
									message.success({
										type: "error",
										content: "Copied",
										className: "custom-class",
										duration: 2,
									});
								}}
							>
								<div className="box-img-copied">
									<img src="./images/copied.png" alt="" />
								</div>
							</CopyToClipboard>
						</div>
					</button>
				</>
				:
				<>
					<button className="btn-connect" onClick={showModal}>
						 Connect
					</button>
				</>
			}

      <Modal
        title="Your wallet"
        visible={isModalVisibleAcc}
        onOk={handleOkAccount}
        footer={false}
        onCancel={handleCancelAccount}
        className="connect-wallet modal-wallet"
      >
        <div className="box-account">
            <div className="account-wl-hi">
              <div className="box-img">
                <img src="./images/account.png" alt="" />
              </div>
              <div className="box-connected">
                Connected
              </div>
            </div>
            <div className="account-wl-bt">
              <div className="txt-left">
              Wallet address: 
              </div>
              <div className="txt-right">
                {accountEllipsisWl}
				<CopyToClipboard
					text={`${account}`}
					onCopy={() => {
					message.success({
						type: "error",
						content: "Copied",
						className: "custom-class",
						duration: 2,
					});
					}}
				>
					<img src="./images/Copy.png" alt="" />
				</CopyToClipboard>
              </div>
            </div>
        </div>
        <div className="gr-disconect">
			<button type="button" className='btn-dis-hold' onClick={handleDisconnect}>
				<img src="./images/logout.png" alt="" /> Disconnect
			</button>
		</div>
      </Modal>

			
			
			{!active ? (
				<Modal
					title={<div className="text-md connect-wallet-title">{!account ? 'Select a wallet to connect' : 'Note'}</div>}
					visible={isModalVisible}
					onOk={handleOk}
					footer={false}
					onCancel={handleCancel}
					className="connect-wallet"
				>
					<div className="flex flex-wrap">
						{WALLET_LIST.map((wallet) => {
							return (
								<WalletItem
									className={`wallet-item`}
									key={wallet.title}
									onClick={() => handleConnect(wallet.connectorId)}
									icon={<wallet.icon width="48px" />}
									title={wallet.title}
								/>
							);
						})}
						{NEED_A_PLACEHOLDER && <div className={walletItemClass} />}
					</div>
				</Modal>
			) : (
				''
			)}
		</>
	);
};

export default ConnectWallet;

