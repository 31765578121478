import React, { useEffect, memo } from "react";
import { useCookies } from "react-cookie";
import { useActiveWeb3React } from "../../hooks";
import { useWeb3React } from "@web3-react/core";
import { Layout } from "antd";

import Header from "../common/Header";
import Footer from "../common/Footer";

const { Content } = Layout;
function Main({ children }: any) {
    return (
        <Layout>
            <Header />
                <Content>{children}</Content>
            <Footer />
        </Layout>
    );
}

export default Main;