/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";
import {postData} from "../../Store"
import { Progress , message , Empty} from 'antd';
import { useActiveWeb3React } from '../../../hooks';
import { useNavigate } from "react-router-dom";

const OwnDetails = () => {
    const { account } = useActiveWeb3React();
    const [dataMint , setDataMint] = useState<any>();
    const [dataItemAfter , setDataItemAfter] = useState([]);

    useEffect(() => {
        if (account) {
            let data:any = {
                "ownerAddress": account
            }
            postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                setDataMint(res.data)
                setDataItemAfter(res.data.nftItems)
            })
        } else {
            let data:any = {
                "ownerAddress": "sss"
            }
            postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                setDataMint(res.data)
            })
        }
	}, [account]);

    const navigate = useNavigate();

    const ItemNft = ({item}:any) => {

        const handlePushDetails = ({tokenId}:any) => {
            navigate(`${"/ownHolder"}?${item.tokenId}`);
        }

        return (
            <>
                <div className="colum w-3">
                    <div className="row-nft" onClick={() => handlePushDetails(item.tokenId)}>
                        <img style={{background: item?.person?.backgroundColor}} src={item?.imageUrl} alt="" />
                        {item.attrs && item.attrs.map((data:any , index:any) => (
                            <div className="img-details-after">
                                <img src={data.imageUrl} alt="" />
                            </div>
                        ))}
                        <div className="txt-nft-id">
                            Holders <span className="main-color">#{item.tokenId}</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    
    const handleBackPage = () => {
        navigate("/own");
    }

    return (
        <>
            <div className="main-wrap-section res cus">
                <div className="container">
                    <div className="content-own-section-details">
                        <div className="title">
                            Your NFT
                        </div>
                        <div className="button-back" onClick={handleBackPage}>
                            <img src="./images/prev.svg" alt="" />
                        </div>
                        <div className="table-details">
                            <div className="columns">
                                {dataItemAfter.length > 0 ?
                                    <>
                                        {dataItemAfter && dataItemAfter.map((item:any , index:any) => (
                                            <ItemNft item={item} />
                                        ))}
                                    </>
                                    :
                                    <>
                                        <div className="non-data">
                                            <Empty description="Non data" />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OwnDetails