import React from 'react';
import {
    Route,
    Routes,
    Navigate,
    BrowserRouter as Router,
} from "react-router-dom";
import Main from "./component/main/Main";
import HomePage from './pages/home';
import Earn from './pages/Earn';
import Staking from "./pages/Staking"
import Rich from "./pages/Rich"
import Own from './pages/Own';
import OwnDetails from './pages/Own/details';
import DetailsInfo from './pages/Own/details-info';
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary, Web3ProviderNetwork } from "./context/web3provider";
import { RefreshContextProvider } from "./context/RefreshContext";
import './App.css';

function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
                <RefreshContextProvider>
                    <div className="App">
                        <Main>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                {/* <Route path="/lp" element={<Earn />} /> */}
                                <Route path="/staking" element={<Staking />} />
                                <Route path="/own" element={<Own />} />
                                {/* <Route path="/rich" element={<Rich />} /> */}
                                <Route path="/ownDetails" element={<OwnDetails />} />
                                <Route path="/ownHolder" element={<DetailsInfo />} />
                            </Routes>
                        </Main>
                    </div>
                </RefreshContextProvider>
            </Web3ProviderNetwork>
        </Web3ReactProvider>
    );
}
export default App;
