/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from "react";
import { useActiveWeb3React } from '../../../hooks';
import { message} from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {postData} from "../../Store"
import { useLocation , useNavigate} from "react-router-dom";
import {isMobile} from 'react-device-detect';

const DetailsInfo = () => {
    const { account } = useActiveWeb3React();
    const [dataMint , setDataMint] = useState<any>();
    const [dataItemAfter , setDataItemAfter] = useState([]);
    let location = useLocation();
    const pathUrl = location.search.split("?");
    const pathId = pathUrl[1]

    useEffect(() => {
        if (account) {
            let data:any = {
                "ownerAddress": account
            }
            postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                setDataMint(res.data)
                setDataItemAfter(res.data.nftItems)
            })
        } else {
            let data:any = {
                "ownerAddress": "sss"
            }
            postData('https://api.hold.vip/api/nft/owneraddress',data).then((res)=>{
                setDataMint(res.data)
            })
        }
	}, [account]);

    const [dataItemAfterNew , setDataItemAfterNew] = useState<any>([dataItemAfter])

    const arrayFilter = (tokenId:any) => {
        const newArrayFilter = dataItemAfter.filter((dataNew:any) => dataNew?.tokenId === Number(pathId));
        setDataItemAfterNew(newArrayFilter);
    };

    useEffect(() => {
        if (dataItemAfter) {
            arrayFilter(pathId)
        }
	}, [dataItemAfter])

    const Item = ({item}:any) => {

        return (
            <>
                <div className="colum w-45">
                    <div className="content-attrs">
                        <div className="title-attrs">
                            Attribute
                        </div>
                        <div className="attrs">
                            {item?.name}
                        </div>
                        {/* <div className="percent">
                            ({item?.rate}%)
                        </div> */}
                    </div>
                </div>
            </>
        )
    }

    const navigate = useNavigate();

    const handleBackPage = () => {
        navigate("/ownDetails");
    }

    return (
        <>
            <div className="main-wrap-section res cus">
                <div className="container">
                    <div className="info-holder">
                        <div className="title">
                            Holder #{pathId}
                        </div>
                        <div className="button-back" onClick={handleBackPage}>
                            <img src="./images/prev.svg" alt="" />
                        </div>
                        <div className="table-holder">
                            <div className="columns">
                                <div className="colum w-6">
                                    <div className="box-img">
                                        <img src={dataItemAfterNew[0]?.imageUrl} style={{background: dataItemAfterNew[0]?.person?.backgroundColor}} alt="" />
                                        {dataItemAfterNew[0]?.attrs !== undefined  && dataItemAfterNew[0]?.attrs.map((data:any , index:any) => (
                                            <div className="img-details-holder">
                                                <img src={data.imageUrl} alt="" />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="info-img">
                                        <div className="left" onClick={() => window.open(dataItemAfterNew[0]?.imageUrl)}>
                                            <img src="./images/info-img.png" alt="" /> View image
                                        </div>
                                        <div className="right">
                                            <CopyToClipboard
                                                text={dataItemAfterNew[0]?.imageUrl}
                                                onCopy={() => {
                                                    message.success({
                                                        type: "error",
                                                        content: "Copied",
                                                        className: "custom-class",
                                                        duration: 2,
                                                    });
                                                }}
                                            >
                                                <img src="./images/link-circle.png" alt="" />
                                            </CopyToClipboard>
                                            Get Url
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="box-holder">
                                        <div className="top">
                                            <div className="item">
                                                <div className="txt-top">
                                                    Rank
                                                </div>
                                                <div className="txt-bottom">
                                                    N/A
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="txt-top">
                                                    Score
                                                </div>
                                                <div className="txt-bottom">
                                                    N/A
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="txt-top">
                                                    Tier
                                                </div>
                                                <div className="txt-bottom">
                                                    N/A
                                                </div>
                                            </div>
                                        </div>
                                        <div className="center">
                                            <div className="item">
                                                <div className="txt-l">
                                                    Contract address: 
                                                </div>
                                                <div className="txt-r">
                                                    {isMobile ?
                                                        <>
                                                            0x39...bF03
                                                        </>
                                                        :
                                                        <>
                                                            0x39...bF03
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="txt-l">
                                                    Flatform:
                                                </div>
                                                <div className="txt-r" onClick={() => window.open('https://opensea.io/collection/hold-vip-collection')}>
                                                    OpenSea
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="txt-l">
                                                    TokenId:
                                                </div>
                                                <div className="txt-r">
                                                    #{pathId}
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="txt-l">
                                                    Blockchain:
                                                </div>
                                                <div className="txt-r">
                                                    Arbitrum
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="columns">
                                                <div className="colum w-45">
                                                    <div className="content-attrs">
                                                        <div className="title-attrs">
                                                            Background
                                                        </div>
                                                        <div className="attrs">
                                                            <div className="bg-attrs" style={{background: dataItemAfterNew[0]?.person?.backgroundColor}}></div>
                                                        </div>
                                                        {/* <div className="percent">
                                                            N/A
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="colum w-45">
                                                    <div className="content-attrs">
                                                        <div className="title-attrs">
                                                            Body
                                                        </div>
                                                        <div className="attrs">
                                                            {dataItemAfterNew[0]?.person?.name}
                                                        </div>
                                                        {/* <div className="percent">
                                                            ({dataItemAfterNew[0]?.person?.rate}%)
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {dataItemAfterNew[0]?.attrs !== undefined  && dataItemAfterNew[0]?.attrs.map((item:any , index:any) => (
                                                    <Item item={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DetailsInfo